exports.components = {
  "component---src-page-templates-question-js": () => import("./../../../src/page-templates/question.js" /* webpackChunkName: "component---src-page-templates-question-js" */),
  "component---src-page-templates-story-js": () => import("./../../../src/page-templates/story.js" /* webpackChunkName: "component---src-page-templates-story-js" */),
  "component---src-page-templates-topic-js": () => import("./../../../src/page-templates/topic.js" /* webpackChunkName: "component---src-page-templates-topic-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-achieve-greece-js": () => import("./../../../src/pages/AchieveGreece.js" /* webpackChunkName: "component---src-pages-achieve-greece-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-hub-js": () => import("./../../../src/pages/digitalHub.js" /* webpackChunkName: "component---src-pages-digital-hub-js" */),
  "component---src-pages-donors-supporters-js": () => import("./../../../src/pages/donors-supporters.js" /* webpackChunkName: "component---src-pages-donors-supporters-js" */),
  "component---src-pages-following-their-stories-js": () => import("./../../../src/pages/followingTheirStories.js" /* webpackChunkName: "component---src-pages-following-their-stories-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-ai-js": () => import("./../../../src/pages/open-ai.js" /* webpackChunkName: "component---src-pages-open-ai-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */)
}

